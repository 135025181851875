.hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25;
}

.navbar-nav .nav-link {
    color: white;
}

.icon {
    width: 100%
}

.card-bg {
    margin: 5px;
}

.card-header {
    color: black;
}

.card-btn {
    font-size: 20px !important;
    margin: 3px;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f0f0f0;
}


@media (max-width: 768px) {
    .nav-menu {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem;
    }
    .card-btn {
        font-size: 16px !important;
        padding: 5px !important;
    }

    /* ... Additional styles for mobile view ... */
}
