.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto;
    /*background-color: #f0f2f5;*/
}

.select-p {
padding: 0.1rem 2.25rem 0.1rem 0.75rem;

}


