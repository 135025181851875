.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
}

.loader path:nth-child(2) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2s linear forwards infinite;
}

.loader path:nth-child(3) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2.3s linear forwards infinite ;
}

.loader path:nth-child(4) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 2.9s linear forwards infinite ;
}

.loader path:nth-child(5) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 3.2s linear forwards infinite ;
}

.loader path:nth-child(6) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 3.5s linear forwards infinite ;
}

.loader path:nth-child(7) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 3.8s linear forwards infinite ;
}

.loader path:nth-child(8) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 4.1s linear forwards infinite ;
}
.loader path:nth-child(9) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 4.4s linear forwards infinite ;
}
.loader path:nth-child(10) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 4.7s linear forwards infinite ;
}
.loader path:nth-child(11) {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: strokeAnimate 5s linear forwards infinite ;
}

@keyframes strokeAnimate {
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (max-width: 768px) {
    .loader-container {
        max-width: 350px;
    }

    .loader {
        width: 350px;
    }
}

@media screen and (max-width: 650px) {
    .loader-container {
        max-width: 300px;
    }

    .loader {
        width: 300px;
    }
}
