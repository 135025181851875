/* Provide sufficient contrast against white background */
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

body {
    /*Google style*/
    font-family: 'Noto Sans TC', sans-serif;
    /*Facebook style*/
    /*font-family: Helvetica, Arial, sans-serif;*/
    /*font-family: "Nunito Sans";*/

    background-color: #f0f2f5;
}
/*body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
}*/

/*.btn-primary {
    color: #fff;
    border: none !important;
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
}*/

.error-template {
    padding: 40px 15px;
    text-align: center;
}

.error-actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

    .error-actions .btn {
        margin-right: 10px;
    }

.table > :not(:first-child) {
    border-top: 0px solid currentColor !important;
}

.notification__item--warning {
    /*background-image: linear-gradient(to top, #f77062 0%, #fe5196 100%);*/
    background-image: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%);
    border: none !important;
}

/*.notification__item--success {
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
    border: none !important;
}*/


.css-93ilmd-DropDown {
    position: absolute;
    top: 43px !important;
    left: -1px;
    border: 1px solid #ccc;
    width: 100% !important;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-height: 300px;
    overflow: auto;
    z-index: 9;
}


/*input[type="text"],
select.form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

    input[type="text"]:focus,
    select.form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }*/