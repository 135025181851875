/* Default styles */
.btn-custom-phone {
    font-size: 16px !important;
    padding: 4px 16px;
    height: 35px;
    color: black;
    font-weight: 500;
}

    .btn-custom-phone .fa-search {
        font-size: 16px;
    }



.AdFiltering-btn {
    font-size: 16px !important;
}
.AdFiltering-card {
    font-size: 14px !important;
}
/* Styles for mobile devices */
@media (max-width: 767px) {
    .btn-custom-phone {
        font-size: 14px !important;
        padding: 6px 6px;
        height: 35px;
        color: black;
        font-weight: 500;
    }

        .btn-custom-phone .fa-search {
            font-size: 14px;
        }
    .AdFiltering-cust-phone {
        font-size: 12px !important;
    }

    .AdFiltering-btn {
        font-size: 12px !important;
        padding: 5px;
    }

    .AdFiltering-card {
        padding: 5px !important;
        font-size: 12px !important;
    }
}
