/*.profile-tabitem {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: right;
    background-color: gray;
    color: black;
}

table tbody tr td {
    border-width: 1px;
    border-style: solid;
    border-color: black;
}*/

/*.nav {
    border-width: 1px;
    border-style: solid;
    border-color: black;
}*/
.styled-table {
    border-collapse: collapse;
    font-family: sans-serif;
    font-weight: bold;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

    .styled-table thead tr {
        background-color: #009879;
        color: #ffffff;
        text-align: left;
        font-size: 18px;
    }

    styled-table th,
    .styled-table td {
        padding: 12px 15px;
        font-size: 12px;
    }

    .styled-table tbody tr {
        border-bottom: 1px solid #dddddd;
        
    }

        .styled-table tbody tr:nth-of-type(even) {
            background-color: #f3f3f3;
        }

        .styled-table tbody tr:last-of-type {
            border-bottom: 2px solid #009879;
        }

        .styled-table tbody tr.active-row {
            font-weight: bold;
            color: #009879;
        }
