@media only screen and (min-width: 600px) {
    .toast {
        width: 32% !important;
    }
}

@media only screen and (max-width: 600px) {
    .toast {
        width: 100% !important;
    }
}

.toast-body {
    cursor: pointer;
}

.toast-header {
    cursor: pointer;
}

a {
    text-decoration: none !important;
}

/*
.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border: 0px solid #b9b9b9 !important;
}

    .react-dropdown-tree-select .dropdown .dropdown-trigger a {
        text-decoration: none !important;
    }
*/

@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.mdl-demo .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
}

.mdl-demo .toggle {
    font: normal normal normal 18px/1 "Material Icons";
    color: #555;
    white-space: pre;
    margin-right: 4px;
}

    .mdl-demo .toggle.collapsed::after {
        cursor: pointer;
        content: "\E5CF";
        vertical-align: middle;
    }

    .mdl-demo .toggle.expanded::after {
        cursor: pointer;
        content: "\E5CE";
        vertical-align: middle;
    }

/* checkbox styles */
.mdl-demo .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
}

    .mdl-demo .checkbox-item:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 2px solid #aaa;
        transition: all 0.3s ease-in-out;
    }

    .mdl-demo .checkbox-item:checked:before {
        height: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-top-style: none;
        border-right-style: none;
        border-color: #2196f3;
    }

.accordion-body {
    padding: 1rem 1.25rem;
    background-color: white;
}
