
.nav-pills .nav-link {
    font-weight: bold;
    padding-top: 8px;
    background: #ced4da;
    color: #000;
    border-radius: 15px;
    height: 100px;
    display: inline-flex;
    align-items: flex-start;
    font-size: 18px;
    justify-content: center; /* �����~�� */
    align-items: flex-start; /* �����~�� */
}
.nav-item {
    margin-right:8px;
}
    .nav-pills .nav-link.active {
        background: #00B395;
        color: #fff;
        gap: 10px;
    }
.tab-content {
    position: absolute;
    background: #fff;
    margin-top: 40px;
    color: #000;
    border-radius: 30px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
    padding: 15px;
    width: 98%;
}
/*z-index: 1000;*//*(�O�@�Ӥ��������, �q�`�Ω�T�O�Y������椬������ܦb������L���e���W�C���O�ݭn�`�N���n�]�m�L������, �קK�y���N�~���л\�ĪG)*/